import { useState } from "react";
import moment from "moment";
import { useQueryClient } from "react-query";
import { DateSelect, Button, ConfirmationDialog } from "../../common";
import { useNotificationStore } from "../../store/notifications";
import supabase from "../../api/supabase";
import { StaffApi } from "../../api";

export const ApproveInvoices = ({ invoicesSelected, setInvoicesSelected }) => {
  const { addNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const [completed, setCompleted] = useState(false);
  const [date, setDate] = useState(moment().format("DD/MM/YYYY"));
  const { user } = supabase.auth.currentSession;
  const { data: authenticatedStaffRecord } = StaffApi.useStaffByEmail(user?.email);

  const approveInvoicesProcess = () => {
    if (!invoicesSelected) {
      return;
    }

    setTimeout(async () => {
      const uniqueJobs = Array.from(new Set(invoicesSelected.map((item) => item.job_id)));
      uniqueJobs.map(async (jobID) => {
        const invoices = invoicesSelected.filter((e) => e.job_id === jobID);

        // send items to xero to create new invoce
        fetch("https://frontier-server-scaffm8.herokuapp.com/xero-submit-invoice", {
          method: "POST", // or 'PUT'
          body: JSON.stringify({
            staff_id: authenticatedStaffRecord?.id,
            invoice_data: {
              type: "invoices",
              endOfMonth: false,
              data: invoices,
              date,
              duplicate: false,
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .catch((error) =>
            addNotification({
              isSuccess: false,
              heading: "Error!",
              content: `An error has occurred. The invoice was not created. Network Error With Xero.`,
            }),
          )
          .then((response) => {
            let isSuccess = true;
            let content = "Invoice created successfully";
            if (response?.err) {
              isSuccess = false;
              content = "Error!";
            }
            setCompleted(true);
            setInvoicesSelected([]);
            setIsLoading(false);
            queryClient.refetchQueries("weeklyHires");
            queryClient.refetchQueries("EDInvoices");
            const heading = response?.message;
            addNotification({
              isSuccess,
              heading,
              content,
            });
          });
      });

      setTimeout(() => {
        setCompleted(true);
        setIsLoading(false);
        setInvoicesSelected([]);
        queryClient.refetchQueries("weeklyHires");
        queryClient.refetchQueries("EDInvoices");
        // window.location.reload();
      }, uniqueJobs.length * 5000);
    }, 1000);
  };

  const handleInputChange = (id, val) => {
    setDate(moment(val).format("DD/MM/YYYY"));
  };

  return (
    <ConfirmationDialog
      isDone={completed}
      icon="info"
      title="Approve Invoices"
      body="This action will send to xero the weekly hire invoices selected. Select A Date for the process"
      triggerButton={
        <Button
          label="Approve Invoices"
          style={{ backgroundColor: "#0078d4", borderRadius: "4px" }}
          icon="submit"
          className="p-button-raised p-3 flex align-items-center justify-content-center"
        >
          Approve Invoices
        </Button>
      }
      confirmButton={
        <Button
          isLoading={isLoading}
          variant="approve"
          onClick={async (e) => {
            setIsLoading(true);
            approveInvoicesProcess();
            // setIsLoading(false);
          }}
          disabled={isLoading}
        >
          Approve Invoices
        </Button>
      }
    >
      <div className="flex">
        <DateSelect
          title="Approve Invoices"
          id="date"
          value={date}
          onChange={handleInputChange}
        />

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </ConfirmationDialog>
  );
};
